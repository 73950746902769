<template>
  <div class="form-group float-right">
    <b-form-checkbox
      v-model="is_favourite"
      name="check-button"
      switch
      size="lg"
      @change="updateFav()"
    >
      Mark as favourite<b-spinner
        small
        variant="primary"
        v-if="current_device.connection_type != -1"
      ></b-spinner>
    </b-form-checkbox>
  </div>
</template>

<script>
import { SET_FAVOURITE_DEVICE } from "@/core/services/store/devices.module";

export default {
  name: "suitch-favourite-widget",
  components: {},
  props: {
    device: null,
    showName: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
  },
  data() {
    return {
      is_favourite: false,
      current_device: { connection_type: -1 },
      statusLoaded: null,
    };
  },
  created: function() {},
  methods: {
    updateFav() {
      if (this.device != null) {
        this.$store
          .dispatch(SET_FAVOURITE_DEVICE, { token: this.device.token,fav:this.is_favourite==true? 1 : 0 })
          // go to which page after successfully login
          .then((data) => {
            // download devices list
            data;
          });
      }
    },
  },
  watch: {
    device: function(oldval, newval) {
      oldval, newval;
      if (oldval!=null && oldval.fav != null) {
          this.is_favourite=Number(oldval.fav)==1 ? true : false;
      }
    },
  },
};
</script>
